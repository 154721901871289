$template-primary--000: var(--template-primary--000) !default;
$template-primary--200: var(--template-primary--200) !default;
$template-primary--400: var(--template-primary--400) !default;
$template-primary--600: var(--template-primary--600) !default;
$template-primary--800: var(--template-primary--800) !default;
$template-primary--900: var(--template-primary--900) !default;

$template-background-color: var(--template-background-color) !default;
$template-default-box-shadow-color: var(
  --template-default-box-shadow-color
) !default;
$template-loader-overlay: var(--template-loader-overlay) !default;
$template-camera-box-shadow-color: var(
  --template-camera-box-shadow-color
) !default;

$default-font-family: "valentino-light", sans-serif;
