@import "../../styles/shared";

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ff_loader_overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: $template-loader-overlay;
  z-index: 10;

  &_hide {
    display: none;
  }
}

.ff_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  @include square(60px);

  svg {
    fill: $template-primary--900;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
}

.ff_loader_loadingText{
  font-size: 16px;
  line-height: 1.4em;
  color: $template-primary--600;
  font-family: "valentino-medium", sans-serif;
  text-align: center;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  @include mq(0px, 420px){
    top: 58%;
    left: 48%;
  }

}
