html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
  user-select: none;
  overflow: auto;
}
